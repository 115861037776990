@import '../../mixins.scss';

.Social-bar {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: flex-end;
    align-items: center;
    bottom: 2rem;
    right: 2rem;

    @include for-phone-only {
        right: 0px;   
        margin: auto;
        bottom: 12%;
        right: 1rem;
        left: 1rem;
    }

}

.Social-text {
    font-size: 1.875rem;
    margin-bottom: 5px;
    color: white;

    @include for-phone-only {
        font-size: 1.25rem;
    }
    
}
  
.Social-button-container {
    display: grid;
    grid-template-columns: repeat( 4, 3rem );
    grid-gap: 1rem;
    max-width: 20rem;

    @include for-phone-only {
        grid-template-columns: repeat( 4, 2.5rem );
    }
    
}