@import "./mixins.scss";

html, body{
  overflow-x: hidden;
}

.App {
  text-align: center;
}

/* .App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
} */

.App-link {
  color: #61dafb;
}

::-webkit-input-placeholder {
  color: black;
}

// body {
//   font-family: "Lato" !important;
// }


// h {
//   font-family: "Poppins";
// }


////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////         CONTAINERS         ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
.main-section {
  display: flex;
  flex-direction: column;
}

.main-container {
  padding: 0 10%;

  @media (max-width: 512px) {
    padding: 0;
    margin: 0 2rem 5% 5%;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full {
  position: fixed;
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
}

////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////         REACT DATES        ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
.DateRangePickerInput__withBorder {
  //border-radius: 4px;
  border: 1px solid #000000;
}

.DateInput {
  width: 33%;
  margin: 2px 0 0 2px;

  @media (max-width: 512px) {
    width: 40%;
  }
}

.DateRangePickerInput_arrow {
  width: 10%;
}

.DateInput_input{
  font-size: 0.825rem;
  padding: 8px 10px 0px;
  font-weight: 500;
  color: black;
  @media ( max-height: 50rem ) {
      font-size: 0.825rem;  
  }
}

////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////         LINES              ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
.Section-divider {
  height: 0.2px;
  width: 50px;
  background-color: black;
  margin: auto;
  border: 1px black solid;
  border-radius: 3px;
}

.hairline-divider {
  height: 1px;
  width: 100%;
  background: rgba( 0, 0, 0, 0.1 );
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.hairline-border-top {
  border-top: 1px solid rgba( 0, 0, 0, 0.1 ) !important;
}

.hairline-border-bottom {
  border-bottom: 1px solid rgba( 0, 0, 0, 0.1 ) !important;
}

.hairline-border-left {
  border-left: 1px solid rgba( 0, 0, 0, 0.1 ) !important;
}

.hairline-border-right {
  border-right: 1px solid rgba( 0, 0, 0, 0.1 ) !important;
}

.hairline-border-vertical {
  border-bottom: 1px solid rgba( 0, 0, 0, 0.1 ) !important;
  border-top: 1px solid rgba( 0, 0, 0, 0.1 ) !important;
}

.hairline-border-horizontal {
  border-left: 1px solid rgba( 0, 0, 0, 0.1 ) !important;
  border-right: 1px solid rgba( 0, 0, 0, 0.1 ) !important;
}

.hairline-border {
  border: 1px solid rgba( 0, 0, 0, 0.1 ) !important;
}

////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////         OTHERS             ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
.no-deco {
  text-decoration: none; 
}

.fixed-size {
    //dont change size on padding
    -webkit-box-sizing: border-box !important; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box !important;    /* Firefox, other Gecko */
    box-sizing: border-box !important;         /* Opera/IE 8+ */
}

.elevated {
  box-shadow: rgba( 0, 0, 0, 0.3 ) 0px 5px 10px !important; 
}

.opaque {
  opacity: 1 !important;
}

.center-of-screen {
  max-width: 1336px;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-left: auto;
  margin-right: auto;
}

.center-of-screen-medium {
  max-width: 1096px;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-left: auto;
  margin-right: auto;
}

.top-right {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////         WRAPPERS           ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////

.amenities-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 0.875rem;
}


////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////         BUTTONS            ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////

button:focus {
  outline:0;
}

button {
  background: none;
  border: none;
  border-radius: inherit;
  padding: 0;
  margin: 0;
  align-items: unset;
  writing-mode: unset;
  text-rendering: unset;
  color: unset;
  letter-spacing: unset;
  word-spacing: unset;
  text-transform: unset;
  font: unset;
  box-sizing: unset;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
  cursor: pointer;
}

.no-select,
.btn {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
  cursor: pointer;
}

.unstyled {
  background: none;
  border: none;
  border-radius: inherit;
  padding: 0;
  margin: 0;
  align-items: unset;
  writing-mode: unset;
  text-rendering: unset;
  color: unset;
  letter-spacing: unset;
  word-spacing: unset;
  text-transform: unset;
  font: unset;
  box-sizing: unset;
}

.filterBtn {
  float: right;
  height: 2rem;
  border-radius: 1rem;
  background: white;
  border: none;
  font-weight: bold;
  color: green;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  cursor: pointer;
}

.closeBtn {
  float: right;
  padding: 0.125rem;
  background: white;
  border: none;
  color: red;
}

////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////         INPUT              ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////

input:focus {
  outline: none;
}

////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////         ANIMATIONS          ///////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes swing { 
  20%, 40%, 60%, 80%, 100% { -webkit-transform-origin: top center; } 
  20% { -webkit-transform: rotate(15deg); } 
  40% { -webkit-transform: rotate(-10deg); } 
  60% { -webkit-transform: rotate(5deg); } 
  80% { -webkit-transform: rotate(-5deg); } 
  100% { -webkit-transform: rotate(0deg); } 
} 
@keyframes swing { 
  20% { transform: rotate(15deg); } 
  40% { transform: rotate(-10deg); } 
  60% { transform: rotate(5deg); } 
  80% { transform: rotate(-5deg); } 
  100% { transform: rotate(0deg); } 
} 

@-webkit-keyframes pulse { 
    0% {
      -moz-box-shadow: none;
      box-shadow: none;
    }
    50% {
        -moz-box-shadow: rgba( 0, 0, 0, 0.3 ) 0px 0px 20px;
        box-shadow: rgba( 0, 0, 0, 0.3 ) 0px 0px 20px;
    }
    100% {
        -moz-box-shadow: none;
        box-shadow: none;
    }
} 
@keyframes pulse { 
    0% {
      -moz-box-shadow: none;
      box-shadow: none;
    }
    50% {
        -moz-box-shadow: rgba( 0, 0, 0, 0.3 ) 0px 0px 20px;
        box-shadow: rgba( 0, 0, 0, 0.3 ) 0px 0px 20px;
    }
    100% {
        -moz-box-shadow: none;
        box-shadow:none;
    } 
} 

////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////         HOVERS             ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////

.hover-opacity {
  opacity: 0.65;
  transition: opacity 300ms ease;
}

.hover-opacity:hover {
  opacity: 1;
}

.hover-background {
  background-color: none;
  transition: background-color 150ms ease;
}

.hover-background:hover {
  background-color: lightgreen;
}

.hover-shadow {
  transition: box-shadow 300ms ease;
}

// .hover-shadow:hover {
//   box-shadow: rgba( 0, 0, 0, 0.3 ) 0px 5px 10px; 
// }

.hover-enlarge {
  transition: transform 300ms ease;
}

.hover-enlarge:hover {
  transform: scale( 1.25, 1.25 )
}

////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////         LAYERS             ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////

.layer-1 {
  z-index: 10 !important;
}

.layer-2 {
  z-index: 11 !important;
}

.layer-3 {
  z-index: 12 !important;
}

.layer-4 {
  z-index: 13 !important;
}

////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////         CURSORS            ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////

.cursor-default {
  cursor: default;
}

////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////         HEADER            ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
.Logo {
  width: 200px;
}

.Header-container {
  background: #fff;
}

.Header-container a {
  color: #5d5d5d;
  text-transform: uppercase;
}

.Header-container a:hover, .Header-container a:active {
  text-decoration: none;
}

.link-container a.TopBar-text-container-right:after {
  content: "|";
  padding-left: 15px;
}

.link-container a.TopBar-text-container-right {
  display:inline-block;
  padding: 0px;
}

.Header-container button.TopBar-text-container-right {
  text-transform: uppercase;
  border: 1px #000 solid;
  padding: 5px 20px;
  background: #000;
  color: #fff;
  height: 1rem;
}

.Header-container button.TopBar-text-container-right:hover, .Header-container button.TopBar-text-container-right:active {
  color: #fff;
}

.Header-container.sticky {
  position: fixed !important;
  top: 0;
  width: 100%;
}
////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////         BODY            ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////

@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Unna&display=swap");


ol,
ul {
    list-style: none;
}

ol.bullet,
ul.bullet {
    list-style: inside;;
}

html {
    font-size: 14pt;
}

@media screen and (max-width: 1680px) {
    html {
        font-size: 11pt;
    }
}

@media screen and (max-width: 980px) {
    html {
        font-size: 12pt;
    }
}

@media screen and (max-width: 736px) {
    html {
        font-size: 11pt;
    }
}

body {
    background-color: #fff;
    color: #000;
}

body {
    font-family: 'Lato', sans-serif !important;
    font-weight: 300 !important;
    font-size: 1rem !important;
    line-height: 1.4 !important;
    letter-spacing: .6px !important;
    -ms-overflow-style: scrollbar;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
}

// a {
//     -moz-transition: color 0.2s;
//     -webkit-transition: color 0.2s;
//     -ms-transition: color 0.2s;
//     transition: color 0.2s;
//     text-decoration: underline;
//     color: #fff;
// }

// a:active,
// a:focus,
// a:hover {
//     text-decoration: none;
//     color: #fff;
// }

strong,
b {
    font-weight: 600 !important;
}

em,
i {
    font-style: italic;
}

p {
    margin: 0 0 2.5rem 0;
}

h1 {
    font-family: 'Unna', serif;
    font-size: 54px !important;
    font-weight: 800 !important;
    line-height: 1.4 !important;
    margin: 0 0 1.5rem 0;
}

h2 {
    font-family: 'Unna', serif;
    font-size: 42px !important;
    font-weight: 700 !important;
    line-height: 1.4 !important;
    margin: 0 0 1.5rem 0;
}

h3 {
    font-family: 'Unna', serif;
    font-size: 28px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    margin: 0 0 1.5rem 0;
}

h4 {
    font-family: 'Unna', serif;
    font-size: 30px !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
    margin: 0 0 1.5rem 0;
}

h5 {
    font-family: 'Unna', serif;
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 1.4 !important;
    margin: 0 0 1.5rem 0;
}

h6 {
    font-family: 'Unna', serif;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 1.4 !important;
    margin: 0 0 1.5rem 0;
}

////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////         FOOTER            ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
.site-footer p 
{
  margin-bottom: 5px;
}

.container
{
  background: #111111 !important;
}
.site-footer
{
  background-color:#111111;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#b3b3b3;
}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}
.site-footer a
{
  color:#b3b3b3;
}
.site-footer a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:#737373
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}

.site-footer a.footer-link {
  padding-right: 20px;
}

.site-footer .social-icons a
{
  width: 38px;
  height: 40px;
  line-height: 38px;
  margin-left: 16px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #bcbcbc;
  color: #111111;
  font-size: 24px;
  display: inline-block;
  text-align: center;
  transition: all .2s linear;
}
.copyright-text
{
  margin:0
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:768px)
{
  .site-footer
  {
    padding-bottom:0
  }

  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}

@media (max-width:500px)
{
  footer.site-footer .text-left, footer.site-footer .text-right {
    text-align: center !important;
  }

  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  } 

  button.user-settings-button {
    width: 25% !important;
}
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.linkedin:hover
{
  background-color:#007bb6
}
.social-icons a.dribbble:hover
{
  background-color:#ea4c89
}