@import '../../mixins.scss';

/* Nest Home CSS */  
.Main-content-container {
    position: relative;
    height: auto;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.Home-page-background {
    object-fit: cover;
    overflow: hidden;
    z-index: -10;

    height: 100%;
    width: 100%;
}

img {
    height: auto;
    max-width: 100%;
    position: relative;
}

.title-1 {
    display: block;
    color: black;
    font-style: normal;
    //font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.5;
    text-align: left;

    @media (max-width: 620px) {
        font-size: 20px;
    }
}

.title-2 {
    display: block;
    color: black;
    font-weight: bold;
    float: left;
    text-align: left;
    font-weight: bold;
    //font-size: 20px;
    margin-bottom: 1rem;
    line-height: 1.5;
}

.title-3 {
    display: block;
    color: black;
    //font-size: 13px;
    margin-bottom: 2rem;

    @media (max-width: 900px) {
        
    }
}

.cls-homepage {
    margin-bottom: 0px !important;
}

.cls-homepage-inner {
    margin: 0 auto;
    position: relative;
    display: inline-flex;
    align-items: center;
}

.cls-homepage-inner p {
    height: 50px;
}

.cls-homepage-title .content-top {
    text-align: left;
    padding-left: 30px;
}

.cls-homepage-title .content-top h3 {
    font-size: 28px !important;
}

.cls-homepage-title {
    margin: 0 auto;
    position: relative;
    display: flow-root;
    align-items: center;
    padding-bottom: 15px;
}

.cls-homepage-title .content-top:before {
    content: '';
    width: 0;
    height: 70%;
    position: absolute;
    border: 2px solid #f6a32d;
    top: 0;
    left: 0;
}

.cls-homepage .section-2 {
    margin: 0 174px 0 14%;
}

.section p {
    color: #666666;
    text-align: left;
}

.cls-homepage-inner .col-lg-3 {
    padding: 5px;
}

.section {
    margin-bottom: 80px;
    position: relative;
}

section > div > div.Main-content-container > div:last-child {
    margin-bottom: 0;
}

.section-1 .Home-page-background {
    height: 600px;

    @include for-phone-only {
        height: 240px;
    }
}

.section-2 {
    min-height: 550px;

    @media (min-width: 685px) and (max-width: 1018px) {
        // min-height: 900px;
    }

    @media (max-width: 685px) {
        // min-height: 1100px;
    }

    @media (max-width: 512px) {
        min-height: 680px;
    }

    @media (max-width: 368px) {
        min-height: 750px;
    }
}

.section-3 {
    display: flex;
    flex-direction: row;
    margin-left: 15%;
    margin-right: 10%;

    @media (max-width: 585px) {
        flex-direction: column;
    }
}

.section-4 {
    margin: 0 10%;
    margin-bottom: 20px;
}

.section-5 {
    position: relative;
    display: flex;
    flex-direction: column;
}

.section-6 {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0 10%;

    @media(max-width: 512px){
        margin: 0 5%;
        flex-direction: column;
    }
}

.section-7 {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10%;
    height: 550px;
    min-height: 550px;

    @media (max-width: 512px) {
        min-height: unset;
        height: unset;
        flex-direction: column;
        width: 100%;
        padding: 0;
        margin: 0 2rem 5% 5%;
    }
}

.section h4 {
    font-size: 24px !important;
    padding-bottom: 15px;

    @media(max-width: 512px){
        margin-bottom: 0;
        padding-bottom: 5px;
    }
}

.img-caption {
    top: calc(50% - 175px);
    background: #eaeaea;
    position: absolute;
    width: 35%;
    z-index: 0;
    height: 350px;

    @media (max-width: 512px) {
        position: relative;
        width: 90%;
        margin: auto;
        height: auto;
    }
}

.img-caption.left {
    left: 6%;

    @media (max-width: 512px) {
        left: unset;
    }
}

.img-caption.right {
    right: 6%;

    @media (max-width: 512px) {
        right: unset;
    }
}

.title-divider {
    margin: 1.5rem 0rem 1rem 0rem;
    min-height: 5px;
    width: 50px;
    background-color: #f6a32d;
    border-radius: 0px;
    text-align: left;
    padding-bottom: 1px;
}

.sec2-desc .title-divider {
    @media(max-width: 512px){
        margin-top: 0.5rem;
    }
}

.title-divider-vertical {
    @media (max-width: 512px) {
        height: 65px !important;
    }
}

.sec2-desc {
    text-align: left;
    padding: 20px;

    @media (max-width: 512px) {
        padding: 10px;
    }
}

.learn-more-button {
    display: flex;
    height: 36px;
    width: 140px;
    background-color: black;
    border-radius: 0px;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 1rem 0 2rem 0;
    transition: transform 150ms ease;

    @media (max-width: 512px){
        margin: 0;
    }
}

.learn-more-button:hover {
    transform: scale(1.12, 1.12);
    color: white;
}

.sec3-desc {
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 550px;
    width: 35%;

    @media (max-width: 585px) {
        width: 100%;
        min-height: 680px;
    }

    @media (max-width: 368px) {
        width: 100%;
        min-height: 750px;
    }
}

.sec4-desc {
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 35%;

    @media (max-width: 512px) {
        width: 100%;
        //min-height: 680px;
        margin-bottom: 1rem;
        padding: 0px;
    }
}

.sec4-desc img {
    height: 320px;
    max-height: 320px;

    object-fit: cover;

    @media (max-width: 512px) {
        width: 100%;
        max-width: unset;
        height: auto;
        max-height: unset;
    }
}

.sec4-title {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
}

.sec4-gallery {
    display: inline-block;
    vertical-align: top;
    align-self: start;
    width: 100%;
    height: auto;
    text-align: left;
}

 .sec6-bg-container {
     position: absolute;
     height: 100%;
     width: 100%;
     top: 0;
     right: 0;
     text-align: right;

     @media (max-width: 900px) {
        position: relative;
    }
 }

 .sec6-bg {
    position: absolute;
    right: 0;
    width: 40%;
    text-align: right;

    @media (max-width: 900px) {
        position: relative;
        width: 100%;
        margin-top: 2rem;
    }
 }

 .sec6-desc-wrapper {
     margin-top: 2rem;
     display: flex;
     flex-direction: row;

     @media (max-width: 599px) {
        flex-direction: column;
    }
 }

 .sec6-desc-container {
     width: 30%;
     text-align: left;
     padding-right: 5%;

     @media (max-width: 599px) {
         width: 100%;
     }

     @media (min-width: 600px) and (max-width: 900px) {
        width: 100%;
    }
 }

 .sec6-desc {
     display: flex;
     flex-direction: row;
 }

 .sec6-desc > p {
    //font-size: 1.2rem;
    color: black;
    font-weight: bold;
    margin-bottom: 1rem;
    padding-left: 10px;
 }

 .sec6-desc > svg {
     padding-top: 5px;
 }

 .sec6-desc > img {
    height: 25px;
    width: 20px;
}

.sec7-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    height: 550px;
    width: 35%;

    @media (max-width: 512px) {
        width: 90%;
        padding: 0;
        height: unset;
    }
}

.main-page-promo-img {
    position: absolute;
    height: auto;
    width: 70%;
    z-index: -1;

    @media (max-width: 512px) {
        height: 275px;
        width: 90%;
        margin: auto;
        position: relative;
    }
}

.main-page-promo-img.left {
    left: 7%;
}

.main-page-promo-img.right {
    right: 7%;

    @media (max-width: 512px) {
        right: unset;
    }
}

.main-page-promo-img2 {
    height: auto;
    width: 70%;

    @media (max-width: 512px) {
        width: 90%;
    }
}

.main-page-promo-img > img {
    max-height: 550px;
}

.section .cls-inner {
    margin-left: 15%;
    margin-right: 15%;

    @media(max-width: 512px){
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 2rem;
    }
}

.section #cta a:hover {
    padding: 5px;
    border-radius: 5px;
}

#cta .content p {
    color: white;
    text-align: center;
}

#cta .image.filtered {
    @media (max-width: 800px) {
        height: 550px !important;
    }
}

#banners {
    max-height: 600px;
    margin: 0 6rem;

    @media (max-width: 800px) {
        max-height: 300px;
        margin: 0;
        padding-top: 60px;
    }
}

#banners img {
    object-fit: cover;
    max-height: 600px;
    width: 100%;

    @media (max-width: 800px) {
        max-height: 300px;
    }
}

.banner-loader {
    height: 600px;
    width: 100%;

    @media (max-width: 800px) {
        height: 300px;
    }
}

.contact-us-box {
    height: 250px;
    min-height: 250px;
    padding: 10px;
    background-color: black;
    color: white;
    margin: 0 10% 5% 10%;

    @media(max-width: 512px){
        margin: 0 5% 5% 5%;
    }
}

.contact-us-box a {
    color: white;
    text-decoration: none;
    padding: 10px;
    border-radius: 2%;
}

.contact-us-box a:hover {
    border: 1px solid white;
}