@import '../../mixins.scss';

.map-marker-wrapper {
    position: relative;
    top: -30px;
    left: -15px;
    cursor: pointer;
}

.map-marker {
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 150ms ease-out;
    transform-origin: 15px 30px;
    z-index: 10;
}

.map-hint {
    position: absolute;
    padding: 1rem;
    background-color: white;
    top: -7.1rem;
    left: -2.7rem;
}