.campaign-ty-page {
    .thank-you-text {
        font-size: 20px;
        font-weight: bold;
    }

    .tick {
        margin-bottom: 20px;
        font-size: 50vh;
        color: green;
    }

    .home-button {
        margin-top: 15px;
        color: white;
        text-decoration: none;
    }
}