@import '../../mixins.scss';

.user-settings-title {
    text-align: start;
    font-size: 2rem;
}

.user-settings-input {
    flex: 10;
    height: 58px;
    border-radius: 10px;
    padding: 0.5rem;
}

.user-settings-desc {
    flex: 2;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1rem;
}

button.user-settings-button {
    display: flex;
    /* height: 50px; */
    width: 10%;
    background-color: black;
    border-radius: 0px;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    color: white;
    margin: 10px 0 0 10px;
    padding: 0 3rem;
}

button.user-settings-button:hover, button.user-settings-button:active, button.user-settings-button:focus {
    background-color: white;
    color: black;
    border: 1px solid black;
}