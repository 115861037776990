.Vertical-icon-with-desc-wrapper {
    display: flex;
    flex-direction: column;
    width: 4rem;
    height: 4rem;
}

.Vertical-icon-wrapper {
    display: flex;
    flex: 1;
}

.Vertical-icon-desc-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Vertical-icon-title {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
}

.Vertical-icon-desc {
    font-size: 1rem;
    margin-top: 0.625rem;
    line-height: 1.25rem;
    text-align: center;
}