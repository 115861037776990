@import '../../mixins.scss';

@media (max-width:1024px) and (min-width:991px) {
    .spotlight.accent2 .cls-image {
        top: 50% !important;
    }

    .spotlight.accent2 .content {
        width: 60% !important;
    }
}

@media (max-width:768px) {
    .contact-label, .contact-details, .contact-social-label, .contact-social-icons {
        width: 100% !important;
        padding: 5px !important;
    }

    .spotlight.accent2 .content {
        padding: 15px !important;
        width: 100% !important;
    }

    .spotlight.accent2 .cls-image {
        width: 100% !important;
        position: unset !important;
    }

    .spotlight.accent2 > .inner {
        display: block !important;
    }

    .spotlight.accent2 h3:before {
        top: 0 !important;
        margin-left: 1rem !important;
    }

    .spotlight.accent2, .spotlight.accent2 .cls-image {
        margin: 0 !important;
    }

    .elevated.layer-1.cls-homestay-filter {
        width: 400px;
    }

    .DayPicker_focusRegion {
        width: 100% !important;
    }
    
    .DayPicker_weekHeader {
        width: calc(100% - 5px) !important;
    }

    .DayPicker,
    .DayPicker > div,
    .DayPicker > div > div,
    .DayPicker_transitionContainer,
    .CalendarMonth_table {
    width: 100% !important;
    }

    .CalendarMonthGrid {
    width: 1000% !important;
    }

    .CalendarMonthGrid_month__horizontal {
    width: calc(10% - 18px) !important;
    }

    .DayPicker_weekHeader {
    width: calc(100% - -20px);
    }

    .CalendarDay,
    .DayPicker_weekHeader_li {
    width: 14.285714286% !important;
    }

    #root > div > section > div > div.below-header.fixed-size > div:nth-child(1) > div > div > div > div > div > div.DayPicker_weekHeaders.DayPicker_weekHeaders_1.DayPicker_weekHeaders__horizontal.DayPicker_weekHeaders__horizontal_2 > div:nth-child(2) {
    display:none;
    }

}

@media (max-width:425px) {
    .spotlight.accent2 > .inner {
        width: calc(100% - 1rem) !important; 
    }
}

@media (max-width:375px) {
    .elevated.layer-1.cls-homestay-filter {
        width: 300px;
        padding: 0 !important;
    }   

    .DayPicker_focusRegion {
        width: 250px;
    }
}

.Contact-us-wrapper {
    height: 100%;
    width: 50%;
    margin: 3rem 2rem 0rem 2rem;

    @media ( max-width: 80em ){
        width: auto;
    }

    @include iphone6p{
        margin: 2rem 0 10rem 0;
    }

    @include iphonex{
        margin: 3rem 0 5rem 0;
    }
}

.Contact-us-title-wrapper {
    margin: 5rem 0;

    @include for-phone-only{
        margin: 2rem 0 3rem 0;
    }
}

.Contact-us-title {
    font-size: 3rem;
    font-weight: bold;

    @include for-phone-only{
        font-size: 2.5rem;
    }

    @include iphonex{
        font-size: 2rem;
    }
}

.Contact-us-icons-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
    max-width: 50rem;
    margin: auto;
    margin-top: 5rem;
    margin-bottom: 5rem;

    @include for-phone-only{
        margin: 2rem 0 1rem 0;
        row-gap: 0.5rem;
    }
}

.cls-google-map {
    position: relative;
    //padding-top: 80px;
    height: 80vh;
    margin: 0 5%;

    @media (max-width: 512px) {
        padding-top: 0;
        margin: 3rem 0 0 0;
    }
}

.Contact-us-connect-wrapper {
    height: 100%;
    width: 50%;
    margin: 5rem 2rem 5rem 2rem;

    @media ( max-width: 80em ){
        width: auto;
    }

    @include iphone6p{
        margin: 0rem 0 10rem 0;
    }

    @include iphone6{
        margin: 0rem 0 8rem 0;
    }

    @include iphonex{
        margin: 0rem 0 15rem 0;
    }

    @media ( min-width: 80em){
        margin: 3rem 2rem 0rem 2rem;
    }
}

.Contact-us-connect-icons-wrapper {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax( 20em, 1fr ) );
    row-gap: 1.5rem;
    column-gap: 1rem;
    max-width: 50rem;
    margin: auto;
    margin-top: 5rem;
    margin-bottom: 5rem;

    @include for-phone-only{
        margin: 2rem 0 0.5rem 0;
        row-gap: 0.5rem;
        grid-template-columns: repeat( auto-fit, minmax( 10em, 1fr ) );
    }
}

.Contact-us-highlight {
    padding: 1rem;
    cursor: pointer;
    margin: 0.125rem;
    text-decoration: none;

    @include for-phone-only{
        margin: 0.5rem;
    }
}

.Contact-us-highlight:hover {
    border-width: 0.125rem;
    border-radius: 1rem;
    border-color: "blue";
    border-style: solid;
    margin: 0rem;

    @include for-phone-only{
        margin: 0.5rem;
        border: none;
    }
}

.spotlight.accent2 .cls-image {
    margin: 60px 0 0 0;
    position: absolute;
    right: 0;
    width: 80%;
    bottom: 0;
    top: 20%;

    @include for-phone-only {
        display: none;
    }
}

.spotlight.accent2 .cls-image img {
    width: 100%;
}

.contact-social-icons a {
  width: 38px;
  height: 40px;
  line-height: 38px;
  margin-left: 16px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #363636;
  font-size: 24px;
  display: inline-block;
  text-align: center;
  transition: all .2s linear;
  color: #fff;
}

.contact-social-icons a:active,
.contact-social-icons a:focus,
.contact-social-icons a:hover {
    background-color: #000;
}

.contact-social {
    padding-top: 35px;
}

.spotlight.accent2 h3:before {
    content: "";
    position: absolute;
    background: #f6a32d;
    width: 70px;
    height: 2px;
    left: 0;
    top: 65px;
    padding-bottom: 5px;
}

.spotlight.accent2 strong, .spotlight.accent2 h3 {
    text-transform: uppercase;
}

.contact-address a {
    color: #000;
    text-decoration: none;
}

.contact-address a:hover, .contact-address a:focus, .contact-address a:active {
    color: #000;
}



button,
.button {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    border-radius: 4px;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    font-size: 0.85rem;
    height: 3rem;
    letter-spacing: 0.04rem;
    line-height: 3rem;
    padding: 0 2.5em;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
}

ul {
    margin: 0 0 2.5rem 0;
    padding-left: 1rem;
}

ul.actions {
    cursor: default;
    list-style: none;
    margin-left: -1.25rem;
    padding-left: 0;
}

.contact-social-icons li {
    display: inline-block;
}

.contact-label {
    width: 30%;
    vertical-align: top;
    display: inline-block;
}

.contact-details {
    display: inline-block;
    width: 70%;
}

.contact-social-icons {
    display: inline-block;
    width: 60%;
}

.contact-social-label {
    display: inline-block;
    width: 40%;    
}

.spotlight.accent2 {
    margin: 1rem 0;
    position: relative;
}

.spotlight.accent2 > .inner {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -moz-align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 75rem;
    width: calc(100% - 4rem);
}

.spotlight h2 {
    -moz-transition: opacity 0.5s ease-out, -moz-transform 0.5s ease-out;
    -webkit-transition: opacity 0.5s ease-out, -webkit-transform 0.5s ease-out;
    -ms-transition: opacity 0.5s ease-out, -ms-transform 0.5s ease-out;
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    margin-bottom: 0;
}

.spotlight .image {
    border-radius: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 45%;
}

.spotlight .image img {
    -moz-object-fit: cover;
    -webkit-object-fit: cover;
    -ms-object-fit: cover;
    object-fit: cover;
    border-radius: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.spotlight.accent2 .content {
    padding: 5rem 5rem 2.5rem 0;
    -moz-transition: opacity 0.5s ease-out, -moz-transform 0.5s ease-out;
    -webkit-transition: opacity 0.5s ease-out, -webkit-transform 0.5s ease-out;
    -ms-transition: opacity 0.5s ease-out, -ms-transform 0.5s ease-out;
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    background-color: #fff;
    border-radius: 4px;
    position: relative;
    z-index: 1;
    width: 55%;
    text-align: left;
}

.spotlight.accent2 {
    background-color: #fff;
    margin-bottom: 70px;    
}

.spotlight.accent2 h2 {
    color: #ffffff;
}

.contact-us-form {
    padding: 10px;
    border: 1px solid rgba(0,0,0,0.1);
    margin: 2rem 5%;
}

.contact-us-form textarea {
    @media(max-width: 512px){
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

.contact-us-form button {
    margin-top: 0.5rem;
    
    @media(max-width: 512px){
        margin-top: 1rem;
    }
}


