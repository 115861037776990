@import '../../mixins.scss';

.SideBarIcon-with-desc-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;

    @include for-phone-only {
        margin-top: 0.2rem;
    }

}

.SideBarIcon-wrapper {
    margin: auto;
    padding: 1rem;
}

.SideBarIcon-desc-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    margin: auto 0;
}

.SideBarIcon-title {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: start;
    color: white;

    @include for-phone-only {
        font-size: 1rem;
    }

}

.SideBarIcon-desc {
    font-size: 0.875rem;
    margin-top: 0.625rem;
    line-height: 1.25rem;
    text-align: start;
}