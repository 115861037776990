@import "../../mixins.scss";

.campaign-page,
.campaign-page h1,
.campaign-page h2 {
    font-family: 'Roboto' !important;
}

.campaign-page #background {
    min-height: 100vh;
    height: 100%;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    object-fit: cover;
    opacity: 0.9;
    z-index: -1;
}

.campaign-page #landing-text {
    background-color: rgba(255,255,255, 0.85);
}

.campaign-page #submit-button {
    text-decoration: none;
    color: white;
    width: 100%;
    margin-top: 15px;
    height: 30px;
    padding: 0;
}

.campaign-page .file-text {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50%;
    white-space: nowrap;
}