@import '../../mixins.scss';

@media (min-width:900px){
    .section-3 h4 {
        min-height: 116px;
        padding-bottom: 0px !important;
        margin-bottom: 0 !important;
    }

    .section-3 .title-2 {
        margin-bottom: 0 !important;
    }
}

@media (max-width:768px){

    .top-wrapper .content-top, .top-wrapper .spotlight.accent1 .cls-image, .top-wrapper .spotlight.accent1 .content {
        width: 100% !important;
    }

    .top-wrapper {
        margin: 0 !important;
    }

    .top-wrapper .spotlight.accent1 > .inner {
        display: block !important;
    }

    .cls-left .spotlight.accent1 .cls-image {
        position: unset !important;
    }

    .spotlight.accent1 {
        padding: 0 !important;
    }

    .spotlight.accent2 {
        padding-bottom: 1rem !important;
    }

    .wrapper.cls-package {
        margin: 70px 0 !important;
    }
    
    .top-wrapper .spotlight.accent1 .content {
        padding-left: 0 !important;
    }

    .cls-inner {
        max-width: 90% !important;
    }

    .cls-package-inner {
        display: block !important;
    }

    #cta .content p {
        font-size: 24px !important;
    }
    
    .cls-about-inner .col {
        display: block !important;
        width: 100% !important;
    }

    .cls-homepage .section-2, .section-3, .section-4 {
        margin: 1rem 5% 0 5%;
    }
    
    .cls-homepage-inner .col-lg-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    
    .color-bg {
        width: 80%  ;
    }
}

@media (max-width:600px) {    
    #cta .content p {
        font-size: 20px !important;
    }   

    #cta .content {
        height: 70% !important;
        width: 75% !important;
    }   
    
    .cls-homepage-inner p {
        height: 20px;
        font-size: 14px;
        line-height: 1.2;
    }

    .section {
        margin-bottom: 0px;
    }
}

@media (max-width:370px) {  
    .cls-homepage-inner p {
        height: 38px;
    }  
    
    .center-of-screen-medium {
        padding: 0 3rem;
    }    
}

.top-wrapper {
    margin: 100px 0;
}

.cls-inner {
    margin: 0 auto;
    position: relative;
    display: flow-root;
    max-width: 74%;
    align-items: center;
}

.top-wrapper .content-top {
    text-align: left;
    width: 60%;
    padding-left: 40px;
}

.content-top h3 {
    font-size: 24px !important;
}

.cls-left  .spotlight.accent1 .cls-image {
    // margin: 60px 0 0 0;
    position: absolute;
    left: 0;
    width: 60%;
    // bottom: 0;
    // top: 0;
}

.spotlight.accent1 .cls-image img {
    width: 100%;
}

.top-wrapper .content-top:before {
    content: '';
    width: 0;
    height: 80%;
    position: absolute;
    border: 2px solid #f6a32d;
    top: 0;
    left: 0;
}

.spotlight.accent1 strong, .spotlight.accent1 h3 {
    text-transform: uppercase;
}


.top-wrapper .spotlight.accent1 {
    padding-top: 30px;
    position: relative;
}

.top-wrapper .spotlight.accent1 > .inner {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -moz-justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 77rem;
    width: calc(100% - 4rem);
    position: relative;

    @media(max-width: 512px){
        width: 90%;
    }
}

.top-wrapper .spotlight.accent1 .content {
    text-align: left;
    padding-top: 20px;
}

.spotlight.accent1 {
    background-color: #fff;
}

.top-wrapper.cls-package h4, .top-wrapper.cls-package p {
    text-align: left;
}

.top-wrapper.cls-package h4 {
    font-weight: 700 !important;
    font-size: 24px !important;
    padding-bottom: 15px;
}

.cls-about-inner {
    margin: 0 auto;
    position: relative;
    display: table;
    max-width: 75%;
    align-items: center;
}

.cls-about-inner .col {
    display: table-cell;
    width: 33.33%;
}

.top-wrapper.cls-package h3 {
    text-align: left;
    text-transform: uppercase;
    padding: 0 0 10px 35px;
}

.top-wrapper.cls-package h3:before {
    content: '';
    width: 0;
    height: 80%;
    position: absolute;
    border: 2px solid #f6a32d;
    top: 0;
    left: 0;
}

#cta .content {
    width: 50%;
    max-width: 100%;
    color: #fff;
    text-align: center;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 55%;
}

.cls-overlay {
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: repeat;
    z-index: 1;
}

#cta .content p {
    border: 1px #fff solid;
    padding: 15px 40px;
    font-size: 28px;
    font-weight: 500 !important;
}

#cta h4 {
    font-size: 18px !important;
    padding-bottom: 15px;
    font-weight: 600 !important;
}

#cta a {
    color: #fff;
    letter-spacing: 2px;
    padding: 0;
}

#cta a:hover, #cta a:active, #cta a:focus {
    text-decoration:unset;
    border:1px #fff solid;
    padding: 0 10px;
}

#cta .image.filtered {
    height: 450px;
}

#cta .image.filtered img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

#cta {
    padding-bottom: 70px;
}

@media (max-width:1024px) and (min-width:991px) {
    .spotlight.accent2 .cls-image {
        top: 50% !important;
    }

    .spotlight.accent2 .content {
        width: 60% !important;
    }
}

@media (max-width:768px) {
    .contact-label, .contact-details, .contact-social-label, .contact-social-icons {
        width: 100% !important;
        padding: 5px !important;
    }

    .spotlight.accent2 .content {
        padding: 15px !important;
        width: 100% !important;
    }

    .spotlight.accent2 .cls-image {
        width: 100% !important;
        position: unset !important;
    }

    .spotlight.accent2 > .inner {
        display: block !important;
    }

    .spotlight.accent2 h3:before {
        top: 0 !important;
        margin-left: 1rem !important;
    }

    .spotlight.accent2, .spotlight.accent2 .cls-image {
        margin: 0 !important;
    }

    .elevated.layer-1.cls-homestay-filter {
        width: 400px;
    }

    .DayPicker_focusRegion {
        width: 100% !important;
    }
    
    .DayPicker_weekHeader {
        width: calc(100% - 5px) !important;
    }

    .DayPicker,
    .DayPicker > div,
    .DayPicker > div > div,
    .DayPicker_transitionContainer,
    .CalendarMonth_table {
    width: 100% !important;
    }

    .CalendarMonthGrid {
    width: 1000% !important;
    }

    .CalendarMonthGrid_month__horizontal {
    width: calc(10% - 18px) !important;
    }

    .DayPicker_weekHeader {
    width: calc(100% - -20px);
    }

    .CalendarDay,
    .DayPicker_weekHeader_li {
    width: 14.285714286% !important;
    }

    #root > div > section > div > div.below-header.fixed-size > div:nth-child(1) > div > div > div > div > div > div.DayPicker_weekHeaders.DayPicker_weekHeaders_1.DayPicker_weekHeaders__horizontal.DayPicker_weekHeaders__horizontal_2 > div:nth-child(2) {
    display:none;
    }

}

@media (max-width:425px) {
    .spotlight.accent2 > .inner {
        width: calc(100% - 1rem) !important; 
    }
}

@media (max-width:375px) {
    .elevated.layer-1.cls-homestay-filter {
        width: 300px;
        padding: 0 !important;
    }   

    .DayPicker_focusRegion {
        width: 250px;
    }
}

.Contact-us-wrapper {
    height: 100%;
    width: 50%;
    margin: 0rem 2rem 0rem 2rem;

    @media ( max-width: 80em ){
        width: auto;
    }

    @include iphone6p{
        margin: 2rem 0 10rem 0;
    }

    @include iphonex{
        margin: 3rem 0 5rem 0;
    }
}

.Contact-us-title-wrapper {
    margin: 5rem 0;

    @include for-phone-only{
        margin: 2rem 0 3rem 0;
    }
}

.Contact-us-title {
    font-size: 3rem;
    font-weight: bold;

    @include for-phone-only{
        font-size: 2.5rem;
    }

    @include iphonex{
        font-size: 2rem;
    }
}

.Contact-us-icons-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
    max-width: 50rem;
    margin: auto;
    margin-top: 5rem;
    margin-bottom: 5rem;

    @include for-phone-only{
        margin: 2rem 0 1rem 0;
        row-gap: 0.5rem;
    }
}

.cls-google-map {
    position: relative;
    height: 80vh;
    margin: 0 5%;
    //padding-top: 80px;

    @media (max-width: 512px) {
        padding-top: 0;
        margin: 3rem 0 0 0;
    }
}

.Contact-us-connect-wrapper {
    height: 100%;
    width: 50%;
    margin: 5rem 2rem 5rem 2rem;

    @media ( max-width: 80em ){
        width: auto;
    }

    @include iphone6p{
        margin: 0rem 0 10rem 0;
    }

    @include iphone6{
        margin: 0rem 0 8rem 0;
    }

    @include iphonex{
        margin: 0rem 0 15rem 0;
    }

    @media ( min-width: 80em){
        margin: 3rem 2rem 0rem 2rem;
    }
}

.Contact-us-connect-icons-wrapper {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax( 20em, 1fr ) );
    row-gap: 1.5rem;
    column-gap: 1rem;
    max-width: 50rem;
    margin: auto;
    margin-top: 5rem;
    margin-bottom: 5rem;

    @include for-phone-only{
        margin: 2rem 0 0.5rem 0;
        row-gap: 0.5rem;
        grid-template-columns: repeat( auto-fit, minmax( 10em, 1fr ) );
    }
}

.Contact-us-highlight {
    padding: 1rem;
    cursor: pointer;
    margin: 0.125rem;
    text-decoration: none;

    @include for-phone-only{
        margin: 0.5rem;
    }
}

.Contact-us-highlight:hover {
    border-width: 0.125rem;
    border-radius: 1rem;
    border-color: "blue";
    border-style: solid;
    margin: 0rem;

    @include for-phone-only{
        margin: 0.5rem;
        border: none;
    }
}

.spotlight.accent2 .cls-image {
    margin: 60px 0 0 0;
    position: absolute;
    right: 0;
    width: 80%;
    bottom: 0;

    @include for-phone-only {
        display: none;
    }
}

.spotlight.accent2 .cls-image img {
    width: 100%;
}

.contact-social-icons a {
  width: 38px;
  height: 40px;
  line-height: 38px;
  margin-left: 16px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #363636;
  font-size: 24px;
  display: inline-block;
  text-align: center;
  transition: all .2s linear;
  color: #fff;
}

.contact-social-icons a:active,
.contact-social-icons a:focus,
.contact-social-icons a:hover {
    background-color: #000;
}

.contact-social {
    padding-top: 35px;
}

.spotlight.accent2 h3:before {
    content: "";
    position: absolute;
    background: #f6a32d;
    width: 70px;
    height: 2px;
    left: 0;
    top: 65px;
    padding-bottom: 5px;
}

.spotlight.accent2 strong, .spotlight.accent2 h3 {
    text-transform: uppercase;
}

.contact-address a {
    color: #000;
    text-decoration: none;
}

.contact-address a:hover, .contact-address a:focus, .contact-address a:active {
    color: #000;
}

button,
.button {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    border-radius: 4px;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    font-size: 0.85rem;
    height: 3rem;
    letter-spacing: 0.04rem;
    line-height: 3rem;
    padding: 0 2.5em;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
}

ul {
    margin: 0 0 2.5rem 0;
    padding-left: 1rem;
}

ul.actions {
    cursor: default;
    list-style: none;
    margin-left: -1.25rem;
    padding-left: 0;
}

.contact-social-icons li {
    display: inline-block;
}

.contact-label {
    width: 30%;
    vertical-align: top;
    display: inline-block;
}

.contact-details {
    display: inline-block;
    width: 70%;
}

.contact-social-icons {
    display: inline-block;
    width: 60%;
}

.contact-social-label {
    display: inline-block;
    width: 40%;    
}

.spotlight.accent2 {
    padding-bottom: 7rem;
    position: relative;
}

.spotlight.accent2 > .inner {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -moz-align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 75rem;
    width: calc(100% - 4rem);
}

.spotlight h2 {
    -moz-transition: opacity 0.5s ease-out, -moz-transform 0.5s ease-out;
    -webkit-transition: opacity 0.5s ease-out, -webkit-transform 0.5s ease-out;
    -ms-transition: opacity 0.5s ease-out, -ms-transform 0.5s ease-out;
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    margin-bottom: 0;
}

.spotlight .image {
    border-radius: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 45%;
}

.spotlight .image img {
    -moz-object-fit: cover;
    -webkit-object-fit: cover;
    -ms-object-fit: cover;
    object-fit: cover;
    border-radius: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.spotlight.accent2 .content {
    padding: 5rem 5rem 2.5rem 0;
    -moz-transition: opacity 0.5s ease-out, -moz-transform 0.5s ease-out;
    -webkit-transition: opacity 0.5s ease-out, -webkit-transform 0.5s ease-out;
    -ms-transition: opacity 0.5s ease-out, -ms-transform 0.5s ease-out;
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    background-color: #fff;
    border-radius: 4px;
    position: relative;
    z-index: 1;
    width: 55%;
    text-align: left;
}

.spotlight.accent2 {
    background-color: #fff;
    margin-bottom: 70px;    
}

.spotlight.accent2 h2 {
    color: #ffffff;
}

.map-contact-us {
    position: absolute;
    top: calc(50% - 160px);
    width: 30%;
    left: 5%;

    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media(max-width: 512px){
        position: relative;
        top: unset;
        width: 100%;
        left: unset;
    }
}
