@import '../../mixins.scss';

.Header-container {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.below-header {
    margin-top: 5rem;
  
    /* @include for-phone-only {
        margin-top: 3rem;
    } */
}

.Logo-container,
.link-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 5rem;
    top: 0;
}

.link-container { 
    flex: 1;
    right: 0;
    justify-content: flex-end;
}

/* .Logo {
    //margin-left: 30px;
} */

.Logo-img {
    @include for-phone-only {
        height: 1rem;
    }
}

.TopBar-text-container-left,
.TopBar-text-container-right,
#profile-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.625rem;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
    font-size: 0.875rem;

    transition: transform 150ms ease;
    outline: none !important;
}

.TopBar-text-container-left:hover,
.TopBar-text-container-right:hover,
#profile-btn:hover {
    transform: scale( 1.12, 1.12 );
}

.TopBar-text-container-right {
    margin-right: 1rem;
}

.TopBar-text-container-left:hover,
.TopBar-text-container-right:hover {
    color: black;
}

.mobile-header-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.mobile-menu-icon-wrapper {
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px;
}

.mobile-logo-wrapper {
    margin-left: 15px;
    flex: 1;
    flex-grow: 1;
    text-align: start;
}

.mobile-logo {
    height: auto;
    width: 7rem;
    object-fit: contain;
}

.mobile-menu-icon-wrapper {
    right: 0;
}

.mobile-menu-wrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-height: 100vh;
    width: 80%;
    right: -80%;
    transition: transform 300ms cubic-bezier(0.47, 0, 0.745, 0.715);
    top: 0;
}

.mobile-menu {
    position: absolute;
    height: 100vh;
    width: 100%;
    background-color: #0f0f0f;
    top: 0;
}

.mobile-menu-close-btn {
    position: absolute;
    top: 1rem;
    left: 1rem;
    height: 2rem;
    width: 2rem;
}

.mobile-menu-content {
    padding: 1rem;
    margin-top: 3.5rem;
    height: 100%;

    @include for-phone-only {
        margin-top: 2rem;
    }
}

////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////         LOGIN MODAL        ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////

/* .modal-input,
.modal-button {
    width: 100%;
    height: 58px;
    border-radius: 10px;
    margin-top: 1rem;
} */

.modal-input {
    width: 80vw;
    max-width: 20rem;
}

.modal-input-icon {
    position: absolute;
    left: 16.5px;
    top: 16.5px;
    padding-right: 16.5px;
}

////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////         USER PANEL         ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////

.user-panel-list-item {
    text-align: start !important;
    font-weight: 500;
    float: left;
    margin: 0;
    padding: 0;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: unset !important;
    font: unset;
    box-sizing: unset;
    outline: none;
}