@import '../../mixins.scss';

.plan-img {
    max-height: 200px;
    object-fit: cover;
}

.plan-container {
    min-height: 80vh;
    padding: 5px;
    margin-bottom: 50px;
    max-width: 20%;
    margin: 0 1% 50px 1%;

    @media(max-width: 512px){
        width: 90%;
        max-width: 100%;
        margin: 0 auto;
    }
}

.plan-desc-container {
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-bottom: 1px solid rgba(0,0,0,0.1);
}
/* 
.plan-container ul {
    padding: 5px;
} */

.plan-container li {
    padding: 5px;
    border-bottom: 1px solid rgba(0,0,0,0.05);
}

.plan-container li:last-child {
    border-bottom: unset;
}