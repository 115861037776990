.Mobile-footer-wrapper {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    -webkit-box-shadow: 0px -4px 5px -3px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px -4px 5px -3px rgba(0,0,0,0.3);
    box-shadow: 0px -4px 5px -3px rgba(0,0,0,0.3);
    z-index: 99;
    padding-top: 10px;
}

.Mobile-footer-link {
    text-decoration: none;
    color: black;
    cursor: default;
}