@mixin for-phone-only {
    @media (max-width: 600px) { @content; }
}

@mixin for-tablet-portrait-up {
    @media (min-width: 601px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

@mixin iphonex {
    @media (max-width: 375px) and (min-height: 812px) { @content; }
}

@mixin iphone6 {
    @media (max-width: 375px) and (min-height: 667px) { @content; }
}

@mixin iphone6p {
    @media (max-width: 414px) { @content; }
}

$breakpoints: (
    min: (
        
        and480  : 480px,
        ip6p    : 414px, 
        ip10    : 375px,
        ip6     : 375px,
        and360  : 360px,
        and320  : 320px,
        ip5     : 320px,
        ip4     : 320px,
        
    ),
    max: (
        and480  : 800px,
        ip6p    : 736px,
        ip10    : 812px,
        ip6     : 667px,
        and360  : 640px,
        and320  : 640px,
        ip5     : 568px,
        ip4     : 480px,
    ),
    ratio: (
        and480: 0,
        ip6p: 3,
        ip10: 3,
        ip6: 2,
        and360: 0,
        and320: 2,
        ip5: 2,
        ip4: 0,
    )
);

/* @mixin smartphone(  $orientation, $keys... ){
    @each $key in $keys {
        $min: map-get( map-get($map: $breakpoints, $key: "min") , $key );
        $max: map-get( map-get($map: $breakpoints, $key: "max") , $key );
        $ratio: map-get( map-get($map: $breakpoints, $key: "ratio") , $key );

        @if $orientation == portrait {
            @if $ratio != 0 {
                @media only screen
                and ( min-device-width: $min )
                and ( max-device-width: $max )
                and ( -webkit-min-device-pixel-ratio: $ratio )
                and ( orientation: portrait ){
                    @content;
                }
            } @else {
                @media only screen
                and ( min-device-width: $min )
                and ( max-device-width: $max )
                and ( orientation: portrait ){
                    @content;
                }
            }
        } @else if $orientation == landscape {
            @if $ratio != 0 {
                @media only screen
                and ( min-device-width: $min )
                and ( max-device-width: $max )
                and ( -webkit-min-device-pixel-ratio: $ratio )
                and ( orientation: landscape ){
                    @content;
                }
            } @else {
                @media only screen
                and ( min-device-width: $min )
                and ( max-device-width: $max )
                and ( orientation: landscape ){
                    @content;
                }
            }
        } @else {
            @if $ratio != 0 {
                @media only screen
                and ( min-device-width: $min )
                and ( max-device-width: $max )
                and ( -webkit-min-device-pixel-ratio: $ratio ){
                    @content;
                }
            } @else {
                @media only screen
                and ( min-device-width: $min )
                and ( max-device-width: $max ){
                    @content;
                }
            }
        }
    }
}

@mixin smartphones( $orientation, $excludes... ){

    @debug $excludes;
    @each $key, $val in map-get($map: $breakpoints, $key: "min") {
        
        @if not index( $excludes, $key ) {
            $min: $val;
            $max: map-get( map-get($map: $breakpoints, $key: "max") , $key );
            $ratio: map-get( map-get($map: $breakpoints, $key: "ratio") , $key );

            @if $orientation == portrait {
                @if $ratio != 0 {
                    @media only screen
                    and ( min-device-width: $min )
                    and ( max-device-width: $max )
                    and ( -webkit-min-device-pixel-ratio: $ratio )
                    and ( orientation: portrait ){
                        @content;
                    }
                } @else {
                    @media only screen
                    and ( min-device-width: $min )
                    and ( max-device-width: $max )
                    and ( orientation: portrait ){
                        @content;
                    }
                }
            } @else if $orientation == landscape {
                @if $ratio != 0 {
                    @media only screen
                    and ( min-device-width: $min )
                    and ( max-device-width: $max )
                    and ( -webkit-min-device-pixel-ratio: $ratio )
                    and ( orientation: landscape ){
                        @content;
                    }
                } @else {
                    @media only screen
                    and ( min-device-width: $min )
                    and ( max-device-width: $max )
                    and ( orientation: landscape ){
                        @content;
                    }
                }
            } @else {
                @if $ratio != 0 {
                    @media only screen
                    and ( min-device-width: $min )
                    and ( max-device-width: $max )
                    and ( -webkit-min-device-pixel-ratio: $ratio ){
                        @content;
                    }
                } @else {
                    @media only screen
                    and ( min-device-width: $min )
                    and ( max-device-width: $max ){
                        @content;
                    }
                }
            }
        } @else {
            @debug index( $excludes, $key );
        }
    }
} */